var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--token-table greyLight pa-5" },
    [
      _vm.token
        ? _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between mb-3" },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c("img", {
                  staticClass: "mr-3",
                  attrs: {
                    src: require("@/assets/images/currencies/icon-btc-gold.svg"),
                    alt: _vm.token,
                    height: "35",
                  },
                }),
                _c("div", [
                  _c("h5", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.token)),
                  ]),
                  _vm.tokenValue
                    ? _c("h5", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.tokenValue)),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "d-flex" }, [
                _vm.button
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "greenPrimary--text font-weight-bold cursor--pointer",
                      },
                      [_vm._v(" " + _vm._s(_vm.button) + " ")]
                    )
                  : _vm._e(),
                _vm.button2
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "greenPrimary--text font-weight-bold ml-4 cursor--pointer",
                      },
                      [_vm._v(" " + _vm._s(_vm.button2) + " ")]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm.vault
        ? _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between mb-3" },
            [
              _vm._m(0),
              _c("mew-button", {
                attrs: {
                  title: "Manage",
                  "btn-size": "small",
                  "btn-style": "outline",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "ul",
        _vm._l(_vm.tableData, function (t, key) {
          return _c("li", { key: key, staticClass: "mt-2" }, [
            _c("div", [_vm._v(_vm._s(t.label))]),
            t.link
              ? _c(
                  "div",
                  {
                    staticClass: "font-weight-bold truncate greenPrimary--text",
                    staticStyle: { "max-width": "120px" },
                  },
                  [
                    _c("a", { attrs: { href: t.link, target: "_blank" } }, [
                      _vm._v(_vm._s(t.value)),
                    ]),
                  ]
                )
              : _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(t.value)),
                ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-center" }, [
      _c("div", [
        _c("h5", { staticClass: "font-weight-bold" }, [_vm._v("ETH")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }